import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { formatProfileJoinedDate } from '@utils/date-time';
import Icon from '@shared/ui/icons/Icon';
import IconText from '@shared/ui/icons/IconText';
import { ReactComponent as CalendarIcon } from '@icons/wolfkit-light/calendar-light.svg';
const ProfileJoinDate = ({ creationDate }) => {
    const { t, i18n } = useTranslation();
    const joinedDate = () => {
        try {
            const date = new Date(creationDate);
            return formatProfileJoinedDate(date, i18n.language);
        }
        catch (ex) {
            return '_';
        }
    };
    return (_jsx(IconText, { IconComponent: (_jsx(Icon, { size: 16, IconComponent: CalendarIcon })), text: `${t('profile.joined')} ${joinedDate()}` }));
};
export default ProfileJoinDate;
